import { matchPath } from 'react-router-dom'
import { isEmpty } from 'ramda'
// Project deps
import { Routes, Route, BasicRoutes } from 'templates/route'
import { tabMap, ProjectTabs, CompanyTabs, UserTabs } from 'templates/tabs'
import { isDocumentationArtifact, isGCPArtifact, isRefStationArtifact } from 'types/artifacts'
import config from 'config'
import { getUserAuthToken } from './users'

export const routeLogoutAccounts = url => {
  return `${config.ACCOUNTS_BASE}/redirect?url=${encodeURIComponent(url)}&redirectUrl=${encodeURIComponent(window.location.href)}&logout=true`
}

export const routeLogin = () => '/'
export const routeRecovery = () => '/recovery'
export const routeEmailConfirmation = () => '/email_confirmation/'
export const routeRegisterSuccess = () => {
  return BasicRoutes.REGISTER_SUCCESS
}
export const routeCompanyUserRegisterSuccess = () => {
  return BasicRoutes.COMPANY_USER_REGISTER_SUCCESS
}
export const routePipeline = id => {
  return getRoute(Route.PIPELINES, id)
}
export const routeRegister = () => {
  return routeLogoutAccounts(BasicRoutes.REGISTER)
}

export const routeLoginAccounts = redirectURL => {
  return `${config.ACCOUNTS_BASE}/login?url=${redirectURL}`
}
export const routeArtifact = id => {
  return getRoute(Route.ARTIFACTS, id)
}
export const routeProject = id => {
  return getRoute(Route.PROJECTS, id)
}
export const routeError = () => {
  return BasicRoutes.ERROR
}
export const routePayments = () => {
  return BasicRoutes.PAYMENTS
}
export const routeProjectUpload = id => {
  return getRoute(Route.PROJECTS, id, ProjectTabs.UPLOAD)
}
export const routeProjectMissions = id => {
  return getRoute(Route.PROJECTS, id, ProjectTabs.MISSIONS)
}
export const routeProjectGCPs = (id, params) => {
  return getRoute(Route.PROJECTS, id, ProjectTabs.GROUNDS) + params
}
export const routeProjectMission = (projectId, id) => {
  return `${BasicRoutes.PROJECTS}/${projectId}/${ProjectTabs.MISSIONS}/${id}`
}
export const routeDashboard = () => {
  return BasicRoutes.DASHBOARD
}
export const routeDashboardProjects = () => {
  return `${BasicRoutes.DASHBOARD}/projects`
}

export const routeProjectArtifacts = (id, artifact = {}) => {
  const { artifactType, mission } = artifact
  if (mission) {
    return getRoute(Route.PROJECTS, id, ProjectTabs.MISSIONS)
  }
  if (isGCPArtifact(artifactType)) {
    return getRoute(Route.PROJECTS, id, ProjectTabs.GROUNDS)
  }
  if (isRefStationArtifact(artifactType)) {
    return getRoute(Route.PROJECTS, id, ProjectTabs.REFS)
  }
  if (isDocumentationArtifact(artifactType)) {
    return getRoute(Route.PROJECTS, id, ProjectTabs.DOCUMENTATION)
  }
  return getRoute(Route.PROJECTS, id, ProjectTabs.DELIVERABLES)
}

export const routeProjectPipelines = (projectId, pipelineId, tab = '', jobRunId = '') => {
  return [BasicRoutes.PROJECTS, projectId, ProjectTabs.PIPELINES, pipelineId, pipelineId && !tab ? 'general' : tab, jobRunId].filter(Boolean).join('/')
}

export const routeProjectPipelinesJobRun = (projectId, pipelineId, jobRunId) => {
  return routeProjectPipelines(projectId, pipelineId, 'job_runs', jobRunId)
}

export const routeJobRun = jobRunId => {
  return `/job_runs/${jobRunId}`
}

export const routeToAccounts = url => {
  const token = getUserAuthToken()
  return `${config.ACCOUNTS_BASE}/redirect?token=${token}&url=${url}&redirectUrl=${window.location.href}`
}

export const routeCompany = id => {
  return routeToAccounts(`company/${id}`)
  // return getRoute(Route.COMPANY, id)
}
export const routeCompanyUsers = id => {
  return routeToAccounts(`company/${id}/users`)
  // return getRoute(Route.COMPANY, id, CompanyTabs.USERS)
}
export const routeCompanyGeneral = id => {
  return routeToAccounts(`company/${id}/general`)
  // return getRoute(Route.COMPANY, id, CompanyTabs.GENERAL)
}
export const routeCompanySubscription = id => {
  return routeToAccounts(`company/${id}/subscription`)
  // return getRoute(Route.COMPANY, id, CompanyTabs.SUBSCRIPTION)
}
export const routeCompanyDataUsage = id => {
  return getRoute(Route.COMPANY, id, CompanyTabs.USAGE)
}
export const routeCompanyShareProjects = id => {
  return `/company/${id}/share_projects`
}
export const routeRovers = serial => {
  return routeToAccounts(`rovers/${serial}`)
}
export const routeUsers = () => {
  return routeToAccounts(`users`)
  // return getRoute(Route.USERS)
}
export const routeUser = id => {
  return routeToAccounts(`users/${id}`)
  // return getRoute(Route.USERS, id)
}
export const routeUserProjects = id => {
  return getRoute(Route.USERS, id, UserTabs.PROJECTS)
}
export const routeUserSubscription = id => {
  return routeToAccounts(`users/${id}/subscription`)
  // return getRoute(Route.USERS, id, UserTabs.SUBSCRIPTION)
}

export const linkRoute = (route, target = '_blank') => {
  const anchor = document.createElement('a')
  anchor.href = `${window.location.origin}${route}`
  anchor.target = target
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}

export const getRoute = (route, id, tabName) => {
  if (tabName) {
    const tabs = tabMap[route] || []
    const foundTab = tabs.find(tab => tab.tabName === tabName)
    let path
    if (foundTab) {
      path = foundTab && foundTab.path
    } else {
      path = tabs[0].path
    }
    if (path) {
      if (typeof path === 'function') {
        return path(id)
      }
    } else {
      return path
    }
  }
  return id ? `${Routes[route]}/${id}` : `${Routes[route]}/`
}

// There is a hacky way to find params to the components that are not children of the Route component
// Whenever you will need some params from the route just add them here and call getParams(location.pathname)
const paths = [
  { path: '/dashboard', exact: true },
  { path: '/dashboard/:tab', exact: true },
  { path: '/projects/:project_id/:tab/:structure_id/job_runs/:job_run_id', exact: true },
  { path: '/projects/:project_id/:tab/:structure_id', exact: true },
  { path: '/projects/:project_id/:tab', exact: true },
  { path: '/projects/:project_id', exact: true },
  { path: '/artifacts/:artifact_id/:tab', exact: true },
  { path: '/artifacts/:artifact_id', exact: true },
  { path: '/pipelines/:pipeline_id/:tab/:job_run_id', exact: true },
  { path: '/pipelines/:pipeline_id/:tab', exact: true },
  { path: '/pipelines/:pipeline_id', exact: true },
  { path: '/job_runs/:job_run_id', exact: true },
  { path: '/payments/:tab', exact: true },
  { path: '/payments' },
  { path: '/users/:user_id/:tab', exact: true },
  { path: '/users/:user_id', exact: true },
  { path: '/users' },
  { path: '/statistics/:tab', exact: true },
  { path: '/statistics/:tab/:usage_tab', exact: true },
  { path: '/statistics' },
]

// This function finds first corresponding path that is isExact = true
export const getMatch = path => {
  path = path || window.location.hash.slice(1, window.location.hash.length)
  const matchObj = paths.map(r => {
    const match = matchPath(path, { path: r.path, exact: Boolean(r.exact) })
    return match
  }).find(p => p && !isEmpty(p) && p.isExact)
  return isEmpty(matchObj) ? {} : (matchObj || {})
}
