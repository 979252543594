import { EventEmitter } from 'events'
import bytes from 'bytes'
// Project deps
// import { createDataDirectory, createDataFile, completeDataDirectory } from '../artifacts/sagas'
// import config from 'config'
import {
  getArtifactById,
  getDataDirectories,
  getAllArtifacts,
} from 'modules/projects/selectors'
import { getUncompletedDataDirectoriesForArtifact, getCompletedDataDirectoriesForArtifact } from 'modules/artifacts/utils'
import PipelinesActions from 'modules/pipelines/actions'
import ArtifactsAPI from 'modules/artifacts/api'
import { token as getToken } from 'modules/users/selectors'
import { isDropboxFile, handleDropboxImportedFiles, getArtifactProperties } from 'modules/importWizard/utils'
// import { upload } from 'utils/chunk-uploader'
import { upload } from 'utils/chunk-uploader/upload1'
import { filterByArtifactId, findById } from 'utils/list'
import { isArtifactTypeUsingDirectoryApi } from 'utils/artifacts'
import { isCameraArtifact, isPointcloudArtifact, isReconDataArtifact } from 'types/artifacts'
// Local deps
import {
  getFailedFileForFile,
  getNextPreparableFile,
  getNextUploadableFile,
  getCurrentArtifact,
  getUploadTransferTries,
  // getIsBusy,
  isSomeFileReadyToBePrepared,
  isSomeFileReadyToBeTransfered,
  getDataDirectoryUploadedFiles,
  getFilesLoaded,
  getPreparedFilesForArtifact,
  getDataFiles,
} from './selectors'
import { parseCamFile, isCamFileName } from './file-types'
import UploadActions, { UploadTypes } from './actions'
import { UploadStatus, getDataFileForFile } from './utils'
import { getAuthentificationHeader, getErrorMessage } from 'utils/api'
import { getFilesForArtifact } from 'utils/upload'
import config, { getIdealChunkSize } from './config'
import axios from 'utils/axios'
import ChunkingCheckSumWorker from './computeChunksWithCheckSum.worker.js'
import ChunkingWorker from './computeChunks.worker.js'

const MAX_RETRIES = config.MAX_RETRIES
const RETRY_TIMEOUT = config.RETRY_TIMEOUT
const WAIT_TIME = config.WAIT_TIME
const PREPARE_WAIT_TIME = config.PREPARE_WAIT_TIME
const UPDATE_STATUS_WAIT_TIME = config.UPDATE_STATUS_WAIT_TIME
const MAX_UPLOAD_ITEMS = config.MAX_UPLOAD_ITEMS
const MAX_UPLOAD_IMAGES_FILES = config.MAX_UPLOAD_IMAGES_FILES
const MAX_PREPARE_IMAGES_FILES = config.MAX_PREPARE_IMAGES_FILES
const MAX_PREPARE_ITEMS = config.MAX_PREPARE_ITEMS
const UPDATE_DATADIRECTORY_EVERY = config.UPDATE_DATADIRECTORY_EVERY
const MAX_CHUNKS_TO_SEND = config.MAX_CHUNKS_TO_SEND

const getDiffSecs = (d1, d2) => {
  const t2 = d2.getTime()
  const t1 = d1.getTime()

  return parseInt((t2 - t1) / 1000)
}

const getUploadRate = (size, duration) => {
  return bytes(size / duration) + '/s'
}

/**
 * Converts a list of chunks as received by the backend into a `ChunkList` as needed by the `chunked-uploader`.
 * This also filters all chunks which were already transmitted in order to avoid to transmit them a second time.
 * @param dataChunks The chunks as received by the backend.
 * @return A `ChunkList` as desired by the backend.
 */
function convertChunks (dataChunks = []) {
  const size = dataChunks.reduce((result, chunk) => chunk.size + result, 0)
  let startByte = 0
  const chunks = dataChunks
    .map(chunk => {
      const convertedChunk = {
        ...chunk,
        startByte,
      }
      startByte += chunk.size
      return convertedChunk
    })
    .filter(chunk => !chunk.completed)
  return { size, chunks }
}

/**
 * Retrieve artifact from the redux state or making a request to the backend
 */
function getArtifact (store, artifactId) {
  const { getArtifact: getArtifactAPICall } = ArtifactsAPI(store)
  const state = store.getState()
  const currentArtifactUploadState = getCurrentArtifact(state)(artifactId)
  let artifact = getArtifactById(state)(artifactId)
  // make a request to the backend to get all data about specific artifact only
  // if it is not yet in the redux state and doesn't have the "cancelled" status
  if (!artifact && (currentArtifactUploadState && !currentArtifactUploadState.cancelled)) {
    artifact = getArtifactAPICall(artifactId)
  }
  return artifact
}

function isSomeFileFromArtifactUploading (artifactId, files) {
  return files.some(file => file.artifactId === artifactId)
}

/**
 * If the we have some artifacts in the uploader queue that are not currently uploading we should refresh the status of that
 * artifacts, so the backend will know that the status if 'Uploading' should be unchanged for another two minutes
 * @param {*} store
 * @param {*} file
 */
async function updateArtifactStatus (store, artifactId, id) {
  const { updateDataFileDescription, updateDataDirectoryDescription, logDataFile, logDataDirectory } = ArtifactsAPI(store)
  const state = store.getState()
  const dataFiles = getDataFiles(state)
  // const filesCurrentlyUploading = state.upload.get('transfer')
  const currentArtifactState = state.upload.get('currentArtifact')[artifactId]
  // If the upload state of the current artifact is not defined the upload was aborted
  // due to an error. No actions should be performed as they already were.
  if (!currentArtifactState || typeof currentArtifactState === 'undefined') {
    return
  }
  if (currentArtifactState) {
    const { dataDirectoryId, fileId } = currentArtifactState
    // Allow updating only once per artifact
    if (fileId !== id) {
      return
    }
    /*
    const shouldUpdateStatus = !isSomeFileFromArtifactUploading(artifactId, filesCurrentlyUploading)
    if (!shouldUpdateStatus) {
      setTimeout(() => updateArtifactStatus(store, artifactId, id), UPDATE_STATUS_WAIT_TIME)
      return
    }
    */
    if (!artifactId) {
      return
    }
    const artifact = getArtifact(store, artifactId)
    if (!artifact) {
      return
    }
    if (isArtifactTypeUsingDirectoryApi(artifact.artifactType)) {
      // Update dataDirectory description
      if (dataDirectoryId) {
        try {
          console.warn('-------UPDATE DATADIRECTORY STATUS-------')
          console.warn('dataDirectoryId = ', dataDirectoryId)
          await updateDataDirectoryDescription(artifactId, dataDirectoryId, '')
          setTimeout(() => updateArtifactStatus(store, artifactId, id), UPDATE_STATUS_WAIT_TIME)
          logDataDirectory({
            level: 'info',
            action: 'UPDATE/DONE',
            artifactId: artifactId,
            dataDirectoryId: dataDirectoryId,
            message: 'Status of the data directory was updated to keep it \'Uploading\'. Run a new loop.',
          })
        } catch (e) {
        }
      } else {
        logDataDirectory({
          level: 'error',
          action: 'UPDATE/ERROR',
          artifactId: artifactId,
          message: 'Status of the data directory was not updated. Data directory ID is undefined',
        })
        console.error('-------NO DATA DIRECTORY ID-------')
        console.error('artifactId = ', artifactId)
        console.error('dataDirectoryId = ', dataDirectoryId)
        console.error('fileId =', id)
      }
    } else {
      // Update dataFile description
      const dataFile = dataFiles.find(dataFile => dataFile.artifactId === artifactId && !dataFile.completed)
      if (dataFile) {
        console.warn('-------UPDATE DATAFILE STATUS-------')
        console.warn('dataFileId = ', dataFile.id)
        try {
          await updateDataFileDescription(artifactId, dataFile.id, '')
          logDataFile({
            level: 'info',
            action: 'UPDATE/DONE',
            artifactId: artifactId,
            dataFileId: dataFile.id,
            message: 'Status of the data file was updated to keep it \'Uploading\'. Run a new loop.',
          })
          setTimeout(() => updateArtifactStatus(store, artifactId, id), UPDATE_STATUS_WAIT_TIME)
        } catch (e) {
        }
      } else {
        logDataFile({
          level: 'error',
          action: 'UPDATE/ERROR',
          artifactId: artifactId,
          message: 'Status of the data file was not updated. Data file ID is undefined',
        })
        console.error('-------NO DATAFILE ID-------')
        console.error('artifactId = ', artifactId)
        console.error('fileId = ', id)
      }
    }
  }
}

async function withRetry (callback, delay, successCallback) {
  try {
    await callback()
    if (successCallback) {
      successCallback()
    }
  } catch (e) {
    const id = setTimeout(() => {
      clearTimeout(id)
      withRetry(callback, delay, successCallback)
    }, delay)
  }
}
/**
 * Called when every chunk for data file is full uploaded
 * @param store The store of the application, holding the application's state.
 * @param next The dispatch function of the next middleware in the chain, used to dispatch redux actions
 * @param artifactId Optional artifact id (for now used only )
 * @param fileId Optional fileId that can be uploaded next
 */
function uploadFileDone (store, next, artifactId, dataFileId) {
  const state = store.getState()
  const { completeDataFile } = ArtifactsAPI(store)
  const dataFiles = getDataFiles(state)
  const dataFile = findById(dataFileId, dataFiles)
  // For every we should call complete data file
  // To do that we need to be sure that every chunk for data file is also completed
  if (dataFile && dataFile.chunks.length > 0 && dataFile.chunks.every(chunk => {
    if (typeof chunk === 'boolean') {
      return chunk
    }
    return chunk.completed
  })) {
    if (!dataFile.completed) {
      withRetry(
        () => {
          completeDataFile(artifactId, dataFileId)
        },
        RETRY_TIMEOUT,
        () => {
          uploadDone(store, next, artifactId, 0, false)
        },
      )
    }
  } else {
    const timeoutId = setTimeout(() => {
      clearTimeout(timeoutId)
      uploadFileDone(store, next, artifactId, dataFileId)
    }, RETRY_TIMEOUT)
  }
}
async function uploadDone (store, next, artifactId, tries, isTimerSet) {
  const state = store.getState()
  const isTimerSetForArtifact = state.upload.get('timerSet')[artifactId]
  // First uploaded file from artifact only should call this function
  // So if timer is already set in the state but called with isTimerSet = false it means
  if (isTimerSetForArtifact && !isTimerSet) {
    return
  }
  const {
    getArtifact: getArtifactAPICall,
    getDataDirectory,
    completeDataDirectory,
    listDataFiles,
    updateDataDirectorySize,
    logDataDirectory,
  } = ArtifactsAPI(store)
  // const { API_BASE: baseUrl } = config
  const artifact = getArtifact(store, artifactId)
  // the artifact may not exist if it is not in the redux state or has the status “cancelled” (this happens when the artifact was deleted during upload)
  if (!artifact) {
    return
  }
  const filesForArtifact = getFilesForArtifact(getFilesLoaded(state), artifactId)
  const dataFiles = getDataFiles(state)
  const doneFilesForArtifact = filesForArtifact.filter(uploadFile => uploadFile.status === UploadStatus.DONE)
  const dataFilesCompleted = isArtifactTypeUsingDirectoryApi(artifact.artifactType)
    ? true
    : doneFilesForArtifact
      .map(file => dataFiles.find(df => df.id === file.dataFileId))
      .every(dataFile => dataFile.completed && dataFile.chunks.every(chunk => chunk.completed))
  const artifactDone = (
    filesForArtifact.length === doneFilesForArtifact.length &&
    dataFilesCompleted
  )
  if (artifactDone) {
    const currentArtifactState = state.upload.get('currentArtifact')[artifactId]
    // If the upload state of the current artifact is not defined the upload was aborted
    // due to an error. No actions should be performed as they already were.
    if (typeof currentArtifactState === 'undefined') {
      return
    }
    next(UploadActions.allFilesDone(artifactId))
    getArtifactAPICall(artifactId)
    // In case we've uploaded a data directory, we'll have to set it to complete.
    if (isArtifactTypeUsingDirectoryApi(artifact.artifactType)) {
      try {
        const { dataDirectoryId } = getCurrentArtifact(state)(artifactId)
        const numberOfFilesBeforeUpload = getDataDirectoryUploadedFiles(state)(dataDirectoryId)
        const numberOfUploadedFiles = filesForArtifact.length
        const { number_of_images: totalNumberOfFiles } = artifact.properties
        const dataDirectory = await getDataDirectory(dataDirectoryId)
        if (
          numberOfFilesBeforeUpload + numberOfUploadedFiles >= totalNumberOfFiles &&
          !dataDirectory.completed
        ) {
          if (dataDirectory.fileIndex.length >= totalNumberOfFiles) {
            await completeDataDirectory(dataDirectoryId)
            logDataDirectory({
              level: 'info',
              action: 'COMPLETE/DONE',
              dataDirectoryId,
              artifactId,
              message: 'Data directory successfully completed!',
            })
          } else {
            console.error('------CANT COMPLETE DATADIRECTORY------')
            console.error('amount of uploaded images = ', dataDirectory.fileIndex.length)
            console.error('amount of images should be uploaded = ', totalNumberOfFiles)
            console.error('tries = ', tries)
            if (tries > 5) {
              logDataDirectory({
                level: 'error',
                action: 'COMPLETE/ERROR',
                dataDirectoryId,
                artifactId,
                message: 'Can\'t complete data directory. ' +
                  'Uploaded # = ' + dataDirectory.fileIndex.length + ', ' +
                  'Session total # = ' + totalNumberOfFiles +
                  'tries = ' + tries,
              })
              return
            }
            console.error('------SET TIMER TO RETRY COMPLETE DATADIRECTORY (10 SECONDS)------')
            logDataDirectory({
              level: 'error',
              action: 'COMPLETE/ERROR',
              dataDirectoryId,
              artifactId,
              message: 'Can\'t complete data directory. ' +
                'Uploaded # = ' + dataDirectory.fileIndex.length + ', ' +
                'Session total # = ' + totalNumberOfFiles +
                'tries = ' + tries,
            })
            if (!isTimerSetForArtifact) {
              next(UploadActions.setTimer(artifactId))
            }
            const timeoutId = setTimeout(() => {
              clearTimeout(timeoutId)
              uploadDone(store, next, artifactId, (tries || 0) + 1, true)
            }, RETRY_TIMEOUT)
          }
        } else {
          logDataDirectory({
            level: 'error',
            action: 'COMPLETE/ERROR',
            dataDirectoryId,
            artifactId,
            message: 'Can\'t complete data directory. ' +
              'Uploaded # = ' + numberOfFilesBeforeUpload + ', ' +
              'Session uploaded # = ' + numberOfUploadedFiles + ', ' +
              'Session total # = ' + totalNumberOfFiles,
          })
        }
      } catch (e) {
        console.error(e)
      }
      return
    }
    // After all files for an artifact have been uploaded, the list of data files
    // has to be refreshed for this artifact.
    listDataFiles(artifactId)

    /*
    if (autoComplete) {
      await completeArtifact(artifactId)
      getArtifact(artifactId)
    }
    */
  } else {
    // If artifact is not done and artifact using data directories
    // We should update the size of the data directory every `UPDATE_DATADIRECTORY_EVERY` uploaded files
    if (
      isArtifactTypeUsingDirectoryApi(artifact.artifactType) &&
      doneFilesForArtifact.length % UPDATE_DATADIRECTORY_EVERY === 0
    ) {
      const { dataDirectoryId } = getCurrentArtifact(state)(artifactId)
      const lastUploadedFiles = doneFilesForArtifact.slice(
        doneFilesForArtifact.length - UPDATE_DATADIRECTORY_EVERY,
        doneFilesForArtifact.length,
      )
      // Update data directory size
      updateDataDirectorySize(artifactId, dataDirectoryId, lastUploadedFiles.reduce((sum, file) => sum + file.file.size, 0))
      // ...and also get an artifact to update UI
      getArtifactAPICall(artifactId)
      if (!isTimerSetForArtifact) {
        next(UploadActions.setTimer(artifactId))
      }
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId)
        uploadDone(store, next, artifactId, 0, true)
      }, RETRY_TIMEOUT)
    } else {
      if (!isTimerSetForArtifact) {
        next(UploadActions.setTimer(artifactId))
      }
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId)
        uploadDone(store, next, artifactId, 0, true)
      }, RETRY_TIMEOUT)
    }
  }
}

/**
 * Performs the actual upload of a previously prepared file. This function will call itself
 * after each successful upload (Please note that there are no stack overflows possible here, as
 * everything is scheduled over the browser's main loop) and start the upload of the next
 * prepared file in the queue in the state of the application. It will take the top element and remove it
 * from the queue if the upload was successful.
 * Currently, if a file failed to upload, then the next upload will not be scheduled and the upload will
 * not continue. This is subject to change in a later MR.
 * @param store The store of the application, holding the application's state.
 * @param next The dispatch function of the next middleware in the chain, used to dispatch redux actions
 * @param artifactId Optional artifact id (for now used only )
 * @param fileId Optional fileId that can be uploaded next
 *  after the `chunked-uploader` emits an event.
 */
async function transfer (store, next, artifactId, fileId, skip = false) {
  const state = store.getState()
  const {
    updateDataDirectory,
    getPresignedUrls,
    completeChunk,
    getDataDirectoryFilePresignedUrl,
    logDataFile,
    logDataDirectory,
  } = ArtifactsAPI(store)
  // const { API_BASE: baseUrl } = config
  const authToken = getToken(state)
  const filesCurrentlyUploading = state.upload.get('transfer')
  const filesCurrentlyPreparing = state.upload.get('prepare')
  let fileShouldBeUploaded = false
  const fileToTransfer = getNextUploadableFile(state, fileId, artifactId)
  // If there is no next uplodable file for the artifact initiate
  if (!fileToTransfer) {
    if (
      filesCurrentlyUploading.length <= 0 &&
      filesCurrentlyPreparing.length <= 0 &&
      isSomeFileReadyToBePrepared(store.getState())
    ) {
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId)
        prepare(store, next)
      }, PREPARE_WAIT_TIME)
      return
    }
    if (
      filesCurrentlyUploading.length <= 0 &&
      filesCurrentlyPreparing.length <= 0 &&
      isSomeFileReadyToBeTransfered(store.getState())
    ) {
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId)
        transfer(store, next)
      }, WAIT_TIME)
      return
    }
    // There is absolutely nothing more to do, so the uploader is no longer busy.
    if (
      !isSomeFileReadyToBeTransfered(store.getState()) &&
      !isSomeFileReadyToBePrepared(store.getState()) &&
      filesCurrentlyUploading.length <= 0 &&
      filesCurrentlyPreparing.length <= 0
    ) {
      next(UploadActions.setBusy(false))
      logDataFile({
        level: 'info',
        action: 'ARTIFACTS/UPLOAD/DONE',
        message: 'Upload is done.',
      })
    }
    return
  }
  const currentUploadingFilesForArtifact = filterByArtifactId(fileToTransfer.artifactId, filesCurrentlyUploading)
  const artifact = getArtifact(store, fileToTransfer.artifactId)
  // the artifact may not exist if it is not in the redux state or has the status “cancelled” (this happens when the artifact was deleted during upload)
  if (!artifact) {
    return
  }
  // For artifact using data directory api
  // We should upload more files because of the size
  if (isArtifactTypeUsingDirectoryApi(artifact.artifactType)) {
    fileShouldBeUploaded = currentUploadingFilesForArtifact.length < MAX_UPLOAD_IMAGES_FILES
  } else {
    fileShouldBeUploaded = currentUploadingFilesForArtifact.length < MAX_UPLOAD_ITEMS
  }
  if (artifactId === fileToTransfer.artifactId && skip) {
    fileShouldBeUploaded = true
  }
  // If amount of currently uploading files is more than allowed
  if (!fileShouldBeUploaded) {
    if (fileToTransfer) {
      const { artifactId, id } = fileToTransfer
      const { fileId: artifactFileId } = getCurrentArtifact(state)(artifactId)
      if (artifactFileId) {
        return
      }
      // If there is no files that currently uploading from the same artifact
      // we should set a timeout to update the artifact status
      if (!isSomeFileFromArtifactUploading(artifactId, filesCurrentlyUploading)) {
        console.warn('-------SET TIMER TO UPDATE ARTIFACT STATUS-------')
        console.warn('artifactId = ', artifactId)
        next(UploadActions.setArtifactUpdateStatus(id, artifactId))
        const timeoutId = setTimeout(() => {
          clearTimeout(timeoutId)
          updateArtifactStatus(store, artifactId, id)
        }, UPDATE_STATUS_WAIT_TIME)
        return
      }
    }
    return
  }
  // If another file exists in the queue, then continue uploading it.
  if (fileToTransfer) {
    const { artifactId, dataFileId, chunks, file, s3KeyPrefix, id } = fileToTransfer
    // Will be called when the upload of the file is done or an error occured.
    next(UploadActions.startTransfer(id, artifactId))
    const timeoutId = setTimeout(() => {
      clearTimeout(timeoutId)
      updateArtifactStatus(store, artifactId, id)
    }, UPDATE_STATUS_WAIT_TIME)
    // If the artifact type is using the data directory Api instead of the default one then the uploading
    // has to be performed using a different uploading technique.
    if (isArtifactTypeUsingDirectoryApi(artifact.artifactType)) {
      // Will be called when image uploading is succeeded
      const ImageUploaded = async () => {
        // When using the directory Api, only one chunk is used.
        // The progress reported is: 1 chunk done, out of 1 chunks in total and 0 chunks failed.
        next(UploadActions.chunkUploaded({ total: 1, done: 1, failed: 0, id }))
        // The upload succeeded.
        next(UploadActions.done({ id, okay: true, artifactId, dataFileId }))
        const uploadDoneTimeoutId = setTimeout(() => {
          clearTimeout(uploadDoneTimeoutId)
          uploadDone(store, next, artifactId, 0)
        }, WAIT_TIME)
        const timeoutId = setTimeout(() => {
          clearTimeout(timeoutId)
          transfer(store, next, artifactId)
        }, WAIT_TIME)
      }
      // Will be called when image uploading is failed
      const ImageFailed = () => {
        // When using the directory Api only one chunk is used.
        // The progress reported is: 0 chunk done, out of 1 chunks in total and 1 chunks failed.
        next(UploadActions.chunkUploaded({ total: 1, done: 0, failed: 1, id }))
        // The upload failed.
        next(UploadActions.imageFailed(id))
        const timeoutId = setTimeout(() => {
          clearTimeout(timeoutId)
          transfer(store, next, artifactId, id)
        }, WAIT_TIME)
      }
      const { dataDirectoryId } = getCurrentArtifact(state)(artifactId)
      logDataDirectory({
        level: 'info',
        action: 'UPLOAD/START',
        artifactId,
        dataDirectoryId,
        message: 'Start uploading',
      })
      if (fileToTransfer.replace) {
        let reader = new FileReader()
        reader.onload = async event => {
          try {
            const data = btoa((event.target).result)
            await updateDataDirectory(dataDirectoryId, s3KeyPrefix, file, data, fileToTransfer.replace)
            ImageUploaded()
          } catch (err) {
            ImageFailed()
          }
          reader = undefined
        }
        reader.onerror = async () => {
          ImageFailed()
          reader = undefined
        }
        reader.readAsBinaryString(file)
        return
      }
      try {
        // For each image we should get a presigned ulr from the backend separately
        let startDate = new Date()
        const result = await getDataDirectoryFilePresignedUrl(artifactId, dataDirectoryId, s3KeyPrefix, file)
        const { okay } = result
        const fileName = file && file.name
        const fileSize = file && file.size
        if (okay) {
          const duration = getDiffSecs(startDate, new Date())
          logDataDirectory({
            level: 'info',
            action: 'PRESIGNED_URL/GET',
            artifactId,
            dataDirectoryId,
            fileName,
            size: fileSize,
            rate: getUploadRate(fileSize, duration),
            duration,
            message: 'Got presigned url for a file ' + '(' + s3KeyPrefix + ', ' + fileName + '). ',
          })
          const onLoadHandler = (startDate, response) => {
            // Request HTTP status code was not `200 OK`.
            if (response.status === 200 || response.status === 204) {
              ImageLoaded(startDate, response)
              return
            }
            errorHandler(startDate, response)
          }
          const ImageLoaded = async (startDate, response) => {
            const duration = getDiffSecs(startDate, new Date())
            logDataDirectory({
              level: 'info',
              action: 'IMAGE/UPLOAD/DONE',
              artifactId,
              dataDirectoryId,
              fileName,
              size: fileSize,
              rate: getUploadRate(fileSize, duration),
              duration,
              message: 'Image successfully uploaded ' + '(' + s3KeyPrefix + ', ' + fileName + '). ',
            })
            // When using the directory Api, only one chunk is used.
            // The progress reported is: 1 chunk done, out of 1 chunks in total and 0 chunks failed.
            next(UploadActions.chunkUploaded({ total: 1, done: 1, failed: 0, id }))
            // The upload succeeded.
            next(UploadActions.done({ id, okay: true, artifactId, dataFileId }))
            const uploadDoneTimeoutId = setTimeout(() => {
              clearTimeout(uploadDoneTimeoutId)
              uploadDone(store, next, artifactId, 0)
            }, WAIT_TIME)
            const timeoutId = setTimeout(() => {
              clearTimeout(timeoutId)
              transfer(store, next, artifactId)
            }, WAIT_TIME)
          }
          const errorHandler = (startDate, request) => {
            logDataDirectory({
              level: 'error',
              action: 'IMAGE/UPLOAD/ERROR',
              artifactId,
              dataDirectoryId,
              fileName,
              status: request.status,
              size: fileSize,
              rate: getUploadRate(fileSize, getDiffSecs(startDate, new Date())),
              duration: getDiffSecs(startDate, new Date()),
              message: 'Error while uploading an image. ' + getErrorMessage(request),
            })
            // When using the directory Api only one chunk is used.
            // The progress reported is: 0 chunk done, out of 1 chunks in total and 1 chunks failed.
            next(UploadActions.chunkUploaded({ total: 1, done: 0, failed: 1, id }))
            // The upload failed.
            next(UploadActions.imageFailed(id))
            const timeoutId = setTimeout(() => {
              clearTimeout(timeoutId)
              transfer(store, next, artifactId, id)
            }, WAIT_TIME)
          }

          const { fields, url } = result.data
          const formData = new FormData()
          let infoString = ''
          Object.keys(fields).forEach(key => {
            formData.append(key, fields[key])
            infoString += ' ' + key + ': ' + fields[key]
          })
          formData.append('file', file)
          startDate = new Date()
          try {
            logDataDirectory({
              level: 'info',
              action: 'IMAGE/UPLOAD/START',
              artifactId,
              dataDirectoryId,
              fileName,
              message: 'Start upload an image. ' + url + infoString,
            })
            const response = await axios({
              method: 'post',
              url: url,
              data: formData,
              headers: { 'Content-Type': 'multipart/form-data' },
            })
            onLoadHandler(startDate, response)
          } catch (e) {
            onLoadHandler(startDate, e)
          }
          /*
          const request = new XMLHttpRequest()
          request.open('POST', url, true)
          request.timeout = 10 * 60 * 1000 // 10 minutes timeout
          request.onerror = errorHandler
          request.onload = onLoadHandler
          request.ontimeout = () => {
            // Request HTTP status code was not `200 OK`.
            if (request.status !== 200 && request.status !== 204) {
              errorHandler()
            } else {
              onLoadHandler()
            }
          }
          // The body as transmitted to the server.
          // Start the request.
          request.send(formData)
          */
          return
        } else {
          ImageFailed()
        }
      } catch (e) {
        logDataDirectory({
          level: 'error',
          action: 'IMAGE/UPLOAD/ERROR',
          artifactId,
          dataDirectoryId,
          message: 'Unknown error?? ' + e.toString(),
        })
        ImageFailed()
      }
      return
    }
    // const url = `${baseUrl}/data_files/${dataFileId}/chunks/{chunkId}`
    const retrievePresignedUrls = async () => {
      return getPresignedUrls(artifactId, dataFileId)
    }
    logDataFile({
      level: 'info',
      action: 'UPLOAD/START',
      artifactId,
      dataFileId,
      message: 'Start uploading',
    })
    const startDate = new Date()
    try {
      let userEmitter = new EventEmitter()
      const list = convertChunks(chunks)
      const fileSize = list.size
      let emitter = upload(
        file,
        list,
        {
          authToken,
          maxRetries: MAX_RETRIES,
          retryTimeout: RETRY_TIMEOUT,
          chunksToSendInParallel: MAX_CHUNKS_TO_SEND,
          // Starting with undefined to get presinged URL inside uploader
          // No need to retrieve them here
          presignedUrls: undefined,
          getPresignedUrls: retrievePresignedUrls,
          getPresignedUrlsRetries: 0,
          logDataFile,
          artifactId,
          dataFileId,
          incomeEmitter: userEmitter,
        },
      )
      next(UploadActions.setCancelFunction(id, userEmitter))
      // Emitted when the upload of a full file is done.
      emitter.on('done', async event => {
        // When the upload is done, notify the application ...
        const { okay, failedChunks } = event
        const duration = getDiffSecs(startDate, new Date())
        next(UploadActions.done({ id, okay, artifactId, dataFileId }))
        if (okay) {
          logDataFile({
            level: 'info',
            action: 'UPLOAD/DONE',
            artifactId,
            dataFileId,
            size: fileSize,
            rate: getUploadRate(fileSize, duration),
            duration,
            message: 'All chunks successfully uploaded!',
          })
        } else {
          logDataFile({
            level: 'error',
            action: 'UPLOAD/ERROR',
            artifactId,
            dataFileId,
            size: fileSize,
            rate: getUploadRate(fileSize, duration),
            duration,
            message: 'Uploaded interrupted! Failed chunks = ' + failedChunks.join(', '),
          })
        }
        const uploadDoneTimeoutId = setTimeout(() => {
          clearTimeout(uploadDoneTimeoutId)
          uploadFileDone(store, next, artifactId, dataFileId)
        }, WAIT_TIME)
        // ... and continue with the next file.
        const timeoutId = setTimeout(() => {
          clearTimeout(timeoutId)
          transfer(store, next, artifactId)
        }, WAIT_TIME)
        emitter = undefined
        userEmitter = undefined
      })
      // Emitted when a chunk is uploaded completely.
      // let previousProgress = 0
      let chunksUploaded = 0
      let prevChunksUploaded = 0
      let timings = 0
      let nextFileCalled = false
      emitter.on('start-upload', event => {
        const { chunk } = event
        logDataFile({
          level: 'info',
          action: 'CHUNK/UPLOAD/START',
          artifactId,
          dataFileId,
          chunkId: chunk.id,
          size: chunk.size,
          message: 'Start to upload chunk',
        })
      })
      emitter.on('chunks-uploaded', event => {
        const { duration } = event
        timings += duration
        next(UploadActions.updateUploadRates(id, timings))
      })
      emitter.on('uploaded', event => {
        const { done, total, failed, chunk, startDate } = event
        // const progress = done / total
        const duration = getDiffSecs(startDate, new Date())
        chunksUploaded++
        const diff = chunksUploaded - prevChunksUploaded
        logDataFile({
          level: 'info',
          action: 'CHUNK/UPLOAD/DONE',
          artifactId,
          dataFileId,
          chunkId: chunk.id,
          size: chunk.size,
          rate: getUploadRate(chunk.size, duration),
          duration: duration,
          message: 'Chunk was successfully uploaded!',
        })
        withRetry(() => completeChunk(artifactId, dataFileId, chunk.id), RETRY_TIMEOUT)
        // Update status of the upload (UI) when MAX_CHUNKS_TO_SEND * 2 chunks uploaded
        // We can update whenever every chunk is uploaded, but for large files it will be a lot of
        // calls to the store
        if (diff >= MAX_CHUNKS_TO_SEND) {
          prevChunksUploaded = diff
          next(UploadActions.chunkUploaded({ total, done, failed, id, chunksUploaded: prevChunksUploaded }))
        }
        const state = store.getState()
        const filesCurrentlyUploading = state.upload.get('transfer')
        const currentUploadingFilesForArtifact = filterByArtifactId(fileToTransfer.artifactId, filesCurrentlyUploading)
        // We can start to upload next file if half of the chunks for the currently file uploaded
        // And we are currently uploading NO MORE THAN 2x from available items MAX_UPLOAD_ITEMS
        if (
          (chunksUploaded / list.chunks.length) >= 0.5 &&
          !nextFileCalled &&
          currentUploadingFilesForArtifact.length < MAX_UPLOAD_ITEMS * 2
        ) {
          nextFileCalled = true
          const timeoutId = setTimeout(() => {
            clearTimeout(timeoutId)
            transfer(store, next, artifactId, undefined, true)
          }, WAIT_TIME)
        }
        emitter = undefined
        userEmitter = undefined
        /*
        if (progress - previousProgress >= MAX_UPLOAD_PROGRESS_DIFFERENCE || 100 - previousProgress < MAX_UPLOAD_PROGRESS_DIFFERENCE) {
          prevChunksUploaded = chunksUploaded - prevChunksUploaded
          previousProgress = progress
          next(UploadActions.chunkUploaded({ total, done, failed, id, chunksUploaded: prevChunksUploaded }))
        }
        */
      })
      emitter.on('cancel', event => {
        // const { file } = event
        // When we cancelling files we still have 'UPLOADING' status for 5 minutes
        // TODO: improve this
        emitter = undefined
        userEmitter = undefined
        logDataFile({
          level: 'info',
          action: 'UPLOAD/CHUNKS/CANCEL',
          artifactId,
          dataFileId,
          message: 'Upload has been cancelled',
        })
      })
      // Emitted when the upload of a single chunk has made progress.
      emitter.on('progress', event => {
        const { progress, chunk } = event
        next(UploadActions.chunkProgress(progress, chunk, id))
      })
      emitter.on('upload-cancelled', event => {
      })
      emitter.on('error-no-chunks', event => {
        // We should avoid this and check for the chunks before upload has even started!
      })
      // Emitted when an error occured during the upload.
      emitter.on('error', async event => {
        console.error('------UPLOAD MIDDLEWARE ERROR------')
        const { done, total, failed, chunk, startDate, status, message } = event
        const duration = getDiffSecs(startDate, new Date())
        logDataFile({
          level: 'error',
          action: 'CHUNK/UPLOAD/ERROR',
          artifactId,
          dataFileId,
          chunkId: chunk.id,
          size: chunk.size,
          rate: getUploadRate(chunk.size, duration),
          duration,
          status,
          message: 'Error while upload chunks is occurred. ' + message,
        })
        const tries = getUploadTransferTries(store.getState(), id)
        if (tries >= MAX_RETRIES) {
          logDataFile({
            level: 'error',
            action: 'CHUNK/UPLOAD/ERROR',
            artifactId,
            dataFileId,
            chunkId: chunk.id,
            size: chunk.size,
            rate: getUploadRate(chunk.size, duration),
            duration,
            status,
            message: 'Error while uploading chunk is occurred too much times. Tries = ' + tries,
          })
          const uploadDoneTimeoutId = setTimeout(() => {
            clearTimeout(uploadDoneTimeoutId)
            uploadDone(store, next, artifactId, 0)
          }, WAIT_TIME)
          // ... and continue with the next file.
          const timeoutId = setTimeout(() => {
            clearTimeout(timeoutId)
            transfer(store, next, artifactId)
          }, WAIT_TIME)
          // setTimeout(() => prepare(store, next, artifactId), PREPARE_WAIT_TIME)
          emitter = undefined
          userEmitter = undefined
        }
        next(UploadActions.uploadFileError(id, artifactId, total, done, failed, file, dataFileId))
      })
    } catch (e) {
      logDataFile({
        level: 'error',
        action: 'UPLOAD/ERROR',
        artifactId,
        dataFileId,
        duration: getDiffSecs(startDate, new Date()),
        message: 'Unknown error?? ' + e.toString(),
      })
    }
  }
}

/**
 * Prepares the next available file in the queue of not yet prepared files in the application's
 * state. Will call itself recursively over the browser's main loop when a file was prepared
 * successfully and continue with the next available file in the queue. If all files have been
 * prepared (the queue is empty), then it will call `transfer()` and start the upload.
 * @param store The store of the application, holding the application's state.
 * @param next The dispatch function of the next middleware in the chain, used to dispatch redux actions.
 */
async function prepare (store, next, artifactId) {
  const { createDataFile, updateDataFile, logDataFile } = ArtifactsAPI(store)
  const state = store.getState()
  const filesCurrentlyUploading = state.upload.get('transfer')
  const filesCurrentlyPreparing = state.upload.get('prepare')
  const fileToPrepare = getNextPreparableFile(state, artifactId)
  // const currentArtifactState = state.upload.get('currentArtifact')[artifactId]
  // If there is no next uplodable file for the artifact initiate
  if (!fileToPrepare) {
    if (
      filesCurrentlyUploading.length <= 0 &&
      filesCurrentlyPreparing.length <= 0 &&
      isSomeFileReadyToBePrepared(store.getState())
    ) {
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId)
        prepare(store, next)
      }, PREPARE_WAIT_TIME)
      return
    }
    if (
      filesCurrentlyUploading.length <= 0 &&
      filesCurrentlyPreparing.length <= 0 &&
      isSomeFileReadyToBeTransfered(store.getState())
    ) {
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId)
        transfer(store, next)
      }, WAIT_TIME)
      return
    }
    // There is absolutely nothing more to do, so the uploader is no longer busy.
    if (
      !isSomeFileReadyToBeTransfered(store.getState()) &&
      !isSomeFileReadyToBePrepared(store.getState()) &&
      filesCurrentlyUploading.length <= 0 &&
      filesCurrentlyPreparing.length <= 0
    ) {
      next(UploadActions.setBusy(false))
    }
    return
  }
  let fileShouldBePrepared = false
  const artId = fileToPrepare.artifactId
  const preparedFilesByArtifact = state.upload.get('preparedFilesByArtifact')[artId] || []
  const artifact = getArtifact(store, artId)
  // the artifact may not exist if it is not in the redux state or has the status “cancelled” (this happens when the artifact was deleted during upload)
  if (!artifact) {
    return
  }
  const currentPrepareFilesForArtifact = filterByArtifactId(artId, filesCurrentlyPreparing)
  if (isArtifactTypeUsingDirectoryApi(artifact.artifactType)) {
    fileShouldBePrepared = currentPrepareFilesForArtifact.length < MAX_PREPARE_IMAGES_FILES
  } else {
    fileShouldBePrepared = currentPrepareFilesForArtifact.length < MAX_PREPARE_ITEMS
  }

  if (!fileShouldBePrepared) {
    return
  }
  // Take the next file which was not yet prepared and prepare it.
  if (fileToPrepare) {
    const { artifactId, file, s3KeyPrefix, id } = fileToPrepare
    const failedFile = getFailedFileForFile(state)(fileToPrepare)
    if (failedFile && failedFile.chunks) {
      next(
        UploadActions.prepareFileDone(
          id,
          failedFile.chunks,
          failedFile.id,
          artifactId,
          s3KeyPrefix,
          failedFile,
          artifactId,
        ),
      )
      logDataFile({
        level: 'info',
        action: 'PREPARE/DONE',
        artifactId: artifactId,
        dataFileId: failedFile.id,
        message: 'No need to prepare file. File already have chunks',
      })
      const uploadTimeoutId = setTimeout(() => {
        clearTimeout(uploadTimeoutId)
        transfer(store, next, artifactId)
      }, WAIT_TIME)
      const prepareTimeoutId = setTimeout(() => {
        clearTimeout(prepareTimeoutId)
        prepare(store, next, artifactId)
      }, PREPARE_WAIT_TIME)
      return
    }
    const fileName = (file && file.file && file.file.name) || (file && file.name) || ''
    const preparedFile = preparedFilesByArtifact.find(file => file.id === id)
    if (preparedFile && preparedFile.chunks) {
      const chunks = preparedFile.chunks
      let dataFile = getDataFileForFile(getDataFiles(state), artifactId, file)
      let dataFileId = ''
      if (!dataFile) {
        logDataFile({
          level: 'info',
          action: 'CREATE/START',
          artifactId,
          fileName,
          message: 'File is prepared. But the data file is not created yet. Creating data file entity...',
        })
        dataFile = await createDataFile(artifactId, file.name, chunks)
        dataFileId = dataFile.id
        logDataFile({
          level: 'info',
          action: 'CREATE/DONE',
          artifactId,
          dataFileId,
          fileName,
          message: 'File is prepared. Data file successfully created',
        })
      } else {
        dataFileId = dataFile.id
        dataFile = await updateDataFile(artifactId, dataFileId, { chunks: chunks })
        logDataFile({
          level: 'info',
          action: 'UPDATE/DONE',
          artifactId,
          dataFileId,
          fileName,
          message: 'Updating dataFile properties (adding chunks)',
        })
      }
      // Indicate that the file was prepared successfully.
      next(
        UploadActions.prepareFileDone(
          id,
          chunks,
          dataFileId,
          artifactId,
          s3KeyPrefix,
          dataFile,
          artifactId,
        ),
      )
      logDataFile({
        level: 'info',
        action: 'PREPARE/DONE',
        artifactId: artifactId,
        fileName,
        dataFileId,
        message: 'File is successfully prepared. Data file is successfully created or found in the list.',
      })
      const uploadTimeoutId = setTimeout(() => {
        clearTimeout(uploadTimeoutId)
        transfer(store, next, artifactId)
      }, WAIT_TIME)
      const prepareTimeoutId = setTimeout(() => {
        clearTimeout(prepareTimeoutId)
        prepare(store, next, artifactId)
      }, PREPARE_WAIT_TIME)
      return
    }
    logDataFile({
      level: 'info',
      action: 'PREPARE/START',
      artifactId: artifactId,
      fileName,
      message: 'Preparing file has been started (' + fileName + ')',
    })
    next(UploadActions.startPrepare(id))
    let worker
    if (isReconDataArtifact(artifact.artifactType)) {
      worker = new ChunkingCheckSumWorker()
    } else {
      worker = new ChunkingWorker()
    }
    // const emitter = prepareFile(file, chunkSize, false)
    const chunkSize = getIdealChunkSize(file.size)
    setTimeout(() => {
      worker.postMessage({
        files: [{ id, fileSize: file.size, file, chunkSize }],
      })
    }, 0)
    const onDone = async (chunks, checkSum) => {
      try {
        let dataFile = getDataFileForFile(getDataFiles(state), artifactId, file)
        let dataFileId = ''
        if (!dataFile) {
          logDataFile({
            level: 'info',
            action: 'CREATE/START',
            artifactId,
            fileName,
            message: 'File is prepared. But the data file is not created yet. Creating data file entity...',
          })
          dataFile = await createDataFile(artifactId, file.name, chunks, checkSum)
          dataFileId = dataFile.id
          logDataFile({
            level: 'info',
            action: 'CREATE/DONE',
            artifactId,
            dataFileId,
            fileName,
            message: 'File is prepared. Data file successfully created',
          })
        } else {
          dataFileId = dataFile.id
          dataFile = await updateDataFile(artifactId, dataFileId, { chunks: chunks, checkSum })
          logDataFile({
            level: 'info',
            action: 'UPDATE/DONE',
            artifactId,
            dataFileId,
            fileName,
            message: 'Updating dataFile properties (adding chunks)',
          })
        }
        // Indicate that the file was prepared successfully.
        next(
          UploadActions.prepareFileDone(
            id,
            chunks,
            dataFileId,
            artifactId,
            s3KeyPrefix,
            dataFile,
            artifactId,
          ),
        )
        logDataFile({
          level: 'info',
          action: 'PREPARE/DONE',
          artifactId: artifactId,
          fileName,
          dataFileId,
          message: 'File is successfully prepared. Data file is successfully created or found in the list.',
        })
        const uploadTimeoutId = setTimeout(() => {
          clearTimeout(uploadTimeoutId)
          transfer(store, next, artifactId)
        }, WAIT_TIME)
        const prepareTimeoutId = setTimeout(() => {
          clearTimeout(prepareTimeoutId)
          prepare(store, next, artifactId)
        }, PREPARE_WAIT_TIME)
        worker.terminate()
      } catch (e) {
        next(UploadActions.dataFileError(id, file, e.message))
        const uploadTimeoutId = setTimeout(() => {
          clearTimeout(uploadTimeoutId)
          transfer(store, next, artifactId)
        }, WAIT_TIME)
        const prepareTimeoutId = setTimeout(() => {
          clearTimeout(prepareTimeoutId)
          prepare(store, next, artifactId)
        }, PREPARE_WAIT_TIME)
        worker.terminate()
      }
    }
    worker.onmessage = function (e) {
      const result = e.data
      // Dispatched when an error occured while preparing the current file.
      if (result.action === 'error') {
        const { error } = result
        next(UploadActions.prepareFileError(error, id))
        logDataFile({
          level: 'error',
          action: 'PREPARE/ERROR',
          message: 'Failed while preparing file. ' + error,
        })
      }
      // Dispatched when the current file was chunked completely.
      if (result.action === 'done') {
        const { result: preparedFiles } = result
        const { chunks, checkSum } = preparedFiles[0]
        onDone(chunks, checkSum)
      }
    }
  } else {
    // If all files were prepared, start uploading all prepared files.
    // TODO: Evaluate over time if this is the right approach, or if the files should be prepared and uploaded
    // sequentially, or even if this can be done in parallel (upload a file and prepare another one while the
    // upload is in progress).
    // setTimeout(() => transfer(store, next), WAIT_TIME)
  }
}

/**
 * Check whether '.cam' files were present in the list of files which the user wants to upload.
 *  - If more than one '.cam' file was present the user will be notified about an error.
 *  - If the '.cam' file was broken the user will be notified about an error.
 *  - If no '.cam' file was present do nothing.
 *  - All files but the '.cam' file will be prefix with the calculated directory name.
 */
async function handleCamFiles (state, next, artifact) {
  // Ignore everything in artifacts with unrelated types.
  if (!artifact || !isCameraArtifact(artifact.artifactType)) {
    return true
  }
  // Get all pending files which belong to the artifact for which the upload should be started.
  const uploadingFiles = getFilesLoaded(state).filter(file =>
    file.artifactId === artifact.id && file.status === UploadStatus.PENDING)
  // Ignore everything but '.cam' files.
  const cameraFiles = uploadingFiles.filter(file => isCamFileName(file.file.name))
  // If more than one camera file was present the artifact is not valid.
  // One artifact shall always contain just one '.cam' file.
  if (cameraFiles.length > 1) {
    // TODO: Internationalization. It is important here.
    // Currently there is no way of performing internationalization on error messages.
    // This has to be implemented in another ticket. Currently: English should be sufficient.
    next(UploadActions.error({
      message: 'More than one camera info file was provided. Only one camera file is allowed per artifact.' +
        'Consider creating a second artifact if more than one camera file is present.',
    }))
    return false
  }

  // If no '.cam' files are present we need to check the artifact property 'sub_folder'
  if (cameraFiles.length <= 0) {
    const { properties } = artifact
    if (properties.sub_folder) {
      next(UploadActions.prefixFiles(
        properties.sub_folder,
        uploadingFiles.map(file => file.file),
      ))
      return true
    }
    // If there is no 'sub_folder' property, artifact is likely corrupted
    return false
  }

  const camFile = cameraFiles[0].file
  try {
    const parsingResult = await parseCamFile(camFile)
    // The camera info file could not be parsed correctly and is likely corrupted. Do not attempt to read
    // the S3 prefix from it.
    if (!parsingResult.okay) {
      next(UploadActions.error('The camera info file seems to be corrupted.'))
      return false
    }
    const { sensorIndex } = parsingResult.result
    const s3KeyPrefix = `cam${sensorIndex}`
    next(UploadActions.prefixFiles(
      s3KeyPrefix,
      uploadingFiles.filter(file => file.file !== camFile).map(file => file.file),
    ))
  } catch (e) {
    console.error(`Error occurred while parseCamFile`)
    console.error(camFile)
    console.error(e)
  }
  return true
}

/**
 * This function is called when artifact properties should be updated
 */
async function handleUpdateArtifact (store, state, artifact) {
  const { updateArtifactProperties } = ArtifactsAPI(store)
  if (isPointcloudArtifact(artifact.artifactType)) {
    await updateArtifactProperties(artifact.id, getArtifactProperties(artifact, state))
  }
}

/**
 * Dropbox files needs special treatment
 * Basically this function should be called only when user try to upload files to artifact that already uploaded
 */
async function handleDropboxFiles (state, next, artifactId, projectId, files, onlyDropboxUploads) {
  const options = {
    [artifactId]: files.map(file => file.file.path),
  }
  const authToken = getToken(state)
  const headers = getAuthentificationHeader(authToken)

  try {
    const result = await handleDropboxImportedFiles({
      headers,
      projectId,
      options,
      onlyDropboxUploads,
      artifactId,
    })
    if (result.okay) {
      const createdPipeline = result.pipeline
      next(PipelinesActions.putGetPipeline(createdPipeline.id, createdPipeline))
    }
  } catch (e) {
    console.error(`Error occured while handleDropboxFiles`)
    console.error(e)
  }
  // Next action remove the files from the uploader state, but as we don't add dropbox files to the state we can omit next line
  // But to stay consistent it's better to dispatch it
  next(UploadActions.startDropboxUpload(artifactId))
}

const startUpload = async (action, next, store) => {
  const { artifactId, autocomplete, dataDirectoryId, projectId, files: artifactFiles } = action
  let filesNotAdded = false
  let _files = []
  let filesForArtifact = []
  if (artifactFiles) {
    _files = artifactFiles
    filesForArtifact = artifactFiles
    filesNotAdded = true
  } else {
    _files = getFilesLoaded(store.getState())
    filesForArtifact = getFilesForArtifact(_files, artifactId)
  }
  const dropboxFiles = filesForArtifact.filter(file => isDropboxFile(file))
  const otherFiles = filesForArtifact.filter(file => !isDropboxFile(file))
  const onlyDropboxUploads = otherFiles.length <= 0
  if (filesNotAdded) next(UploadActions.addFiles(otherFiles, artifactId, projectId))
  // Add only non-dropbox files to uploader state
  const state = store.getState()
  const artifact = findById(artifactId, getAllArtifacts(state))
  const { createDataDirectory, createDataFiles, getDataDirectory } = ArtifactsAPI(store)
  // Update artifact properties
  await handleUpdateArtifact(store, state, artifact)
  // Camera Info files need special treatment.
  const camFileStatus = await handleCamFiles(state, next, artifact)
  // Something went wrong when handling the camera info file. Likely there were more than one
  // '.cam' files provided or the file was corrupted. Abort the upload.
  if (!camFileStatus) {
    return
  }
  if (dropboxFiles.length > 0) {
    await handleDropboxFiles(state, next, artifactId, projectId, dropboxFiles, onlyDropboxUploads)
  }
  // If there is no files except that are uploaded from dropbox (or other uploader?) we just skip
  if (onlyDropboxUploads) {
    next(UploadActions.allFilesDone(artifactId))
  } else {
    const stateAfterDropboxFilesRemoved = store.getState()
    // If the artifact type is using the data directory Api instead of the default one
    // then a data directory needs to be created.
    _files = getFilesLoaded(stateAfterDropboxFilesRemoved)
    filesForArtifact = getFilesForArtifact(_files, artifactId)
    if (isArtifactTypeUsingDirectoryApi(artifact.artifactType)) {
      const dataDirectories = getDataDirectories(state)
      const dataDirectoryById = findById(dataDirectoryId, dataDirectories)
      if (dataDirectoryById) {
        const dataDirectory = await getDataDirectory(dataDirectoryId)
        next(
          UploadActions.setDataDirectoryUploadedFiles(
            dataDirectoryId,
            dataDirectory.fileIndex.length,
          ),
        )
        next(
          UploadActions.startUpload(
            artifactId,
            autocomplete,
            dataDirectoryId,
          ),
        )
      } else {
        const uncompletedDirectories = getUncompletedDataDirectoriesForArtifact(artifactId, dataDirectories)
        const completedDirectories = getCompletedDataDirectoriesForArtifact(artifactId, dataDirectories)
        // Here we need to check if there is some files with 'replace' properties.
        // It means that we not need to create another dataDirectory but use currently one if dataDirectory exist
        if (filesForArtifact.find(file => file.replace) && completedDirectories.length > 0) {
          next(
            UploadActions.startUpload(
              artifactId,
              autocomplete,
              completedDirectories[0].id,
            ),
          )
        } else if (uncompletedDirectories.length > 0) {
          const [firstUncompletedDataDirectory] = uncompletedDirectories
          const firstUncompletedDataDirectoryId = firstUncompletedDataDirectory.id
          const dataDirectory = await getDataDirectory(firstUncompletedDataDirectoryId)
          next(
            UploadActions.setDataDirectoryUploadedFiles(
              firstUncompletedDataDirectoryId,
              dataDirectory.fileIndex.length,
            ),
          )
          next(
            UploadActions.startUpload(
              artifactId,
              autocomplete,
              firstUncompletedDataDirectoryId,
            ),
          )
        } else {
          const dataDirectory = await createDataDirectory(artifactId)
          next(
            UploadActions.setDataDirectoryUploadedFiles(
              dataDirectory.id,
              0,
            ),
          )
          // Augment the action with the new `dataDirectoryId`.
          next(
            UploadActions.startUpload(
              artifactId,
              autocomplete,
              dataDirectory.id,
            ),
          )
        }
      }
      // We can upload image files without any preparing
      // So do it
      next(UploadActions.prepareFilesDone(artifactId))
      const newState = store.getState()
      const filesToTransfer = getPreparedFilesForArtifact(newState, artifactId)
      // And initiate uploading for the first `MAX_UPLOAD_IMAGES_FILES` files of this artifact
      filesToTransfer.slice(0, MAX_UPLOAD_IMAGES_FILES).forEach(file => {
        const timeoutId = setTimeout(() => {
          clearTimeout(timeoutId)
          transfer(store, next, artifactId)
        }, WAIT_TIME)
        next(UploadActions.setBusy(true))
      })
    } else {
      // We create all the dataFiles of the artifact here at once with empty list of chunks every
      // And then we use the dataFiles array to get the dataFile we needed
      const dataFilesForArtifact = getFilesForArtifact(getDataFiles(state), artifactId)
      const filesWithoutDataFile = filesForArtifact.filter(file => {
        const dataFileFound = Boolean(getDataFileForFile(dataFilesForArtifact, artifactId, file))
        return !dataFileFound
      })
      next(UploadActions.startUploadDataFile(artifactId))
      let dataFiles = []
      if (filesWithoutDataFile.length > 0) {
        // Create dataFiles for all the files that don't have one at once
        dataFiles = await createDataFiles(artifactId, filesWithoutDataFile)
      }
      next(UploadActions.addDataFiles(dataFiles))
      next(UploadActions.startUpload(artifactId, autocomplete, undefined))
      next(action)
      // Do not start doing anything if the uploader is already busy.
      // const isBusy = () => false
      const newState = store.getState()
      // const isUploaderBusy = getIsBusy(newState)
      // const fileToPrepare = isSomeFileReadyToBePrepared(newState, artifactId)
      const filesToPrepare = getFilesLoaded(newState).filter(file => file.artifactId === artifactId)
      if (isReconDataArtifact(artifact.artifactType)) {
        next(UploadActions.setBusy(true))
        prepare(store, next, artifactId)
      } else {
        const worker = new ChunkingWorker()
        setTimeout(() => {
          worker.postMessage({
            files: filesToPrepare.map(file => ({
              id: file.id,
              fileSize: file.file.size,
              chunkSize: getIdealChunkSize(file.file.size),
            })),
          })
        }, 0)
        worker.onmessage = function (e) {
          const result = e.data
          // Dispatched when the files were chunked completely.
          if (result.action === 'done') {
            const { result: preparedFiles } = result
            next(UploadActions.prepareFilesDone(artifactId, preparedFiles))
            const timeoutId = setTimeout(() => {
              clearTimeout(timeoutId)
              prepare(store, next, artifactId)
            }, PREPARE_WAIT_TIME)
          }
        }
      }
    }
  }
}

const actions = {
  [UploadTypes.START_UPLOAD]: startUpload,
}

function runAction (action, next, store) {
  const doAction = actions[action.type]
  return (
    doAction
      ? doAction(action, next, store)
      : next(action)
  )
}

export const uploadMiddleware = store => next => action => {
  return runAction(action, next, store)
}
